.pagination-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.page-button {
  background-color: #2a2e35;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  border-radius: 4px;
  cursor: pointer;
}

.page-button:hover {
  background-color: #3d85c6;
}

.page-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-button.active {
  background-color: #3d85c6;
}