.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: #0f1923;
  color: #ffffff;
}

.logo {
  height: 28px;
}

.search-bar-wrapper {
  position: relative;
  width: 40%;
  max-width: 480px;
}

.search-bar {
  width: 100%;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  border-radius: 4px;
  border: 1px solid #2a3744;
  background-color: #1a2734;
  color: #ffffff;
  font-size: 0.875rem;
}

.search-bar:focus {
  outline: none;
  border-color: #3d85c6;
}

.search-icon {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #5f7285;
}

.nav {
  display: flex;
  align-items: center;
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
  margin-left: 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: color 0.2s ease;
}

.nav-link:hover {
  color: #3d85c6;
}

.search-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #1a2734;
  border: 1px solid #2a3744;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 10;
}

.suggestion-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: #ffffff;
  text-decoration: none;
}

.suggestion-item:hover {
  background-color: #2a3744;
}

.suggestion-image {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 0.5rem;
}