.sidebar-wrapper {
  width: 250px;
  background-color: #2a2e35;
  padding: 1rem;
  border-radius: 4px;
  height: 100%;
  overflow-y: auto;
}

.filter-section {
  margin-bottom: 1.5rem;
}

.filter-title {
  font-size: 1rem;
  margin-bottom: 0.75rem;
  color: #ffffff;
  border-bottom: 1px solid #3d4148;
  padding-bottom: 0.5rem;
}

.filter-list {
  list-style-type: none;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
}

.filter-item {
  margin-bottom: 0.5rem;
}

.filter-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.filter-checkbox {
  margin-right: 0.5rem;
  cursor: pointer;
}

.filter-name {
  font-size: 0.9rem;
  color: #c4c4c4;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.75rem;
  background-color: #3d4148;
  border: 1px solid #4a4f57;
  border-radius: 4px;
  color: #ffffff;
  font-size: 0.9rem;
}

.search-input:focus {
  outline: none;
  border-color: #5a6270;
}