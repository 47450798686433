.card {
  background-color: #2a2e35;
  border-radius: 4px;
  overflow: hidden;
  transition: transform 0.2s;
  text-decoration: none;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.card:hover {
  transform: translateY(-5px);
}

.skin-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
  background-color: #3a3f48;
}

.skin-info {
  padding: 0.75rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.skin-name {
  margin: 0 0 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.skin-rarity {
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}

.skin-weapon {
  font-size: 0.75rem;
  color: #8f9296;
  margin-top: auto;
}